import { useLazyQuery } from '@apollo/client';
import React, { useState, createContext, ReactNode, useContext } from 'react';
import { useForm } from 'app/utils/hooks/useForm';
import { IFilterContext, IFilterValues } from './TypedFilterContext';

// Types
import { ISelectedSort } from '../components/sort/TypesSort';

// Context
import { AlertContext } from 'app/notifications/context/Alert/Alert';

// api
import {
  GET_MARKETPLACE_DEALS_V2,
  GET_MM_FILTERS_COUNT,
  GET_FIRM_TOAST_LIMIT_QUERY,
} from 'api/graphql/marketplace/queries';

// Constants
import { DefaultMMSort } from '../constants';

const LIMIT_ITEMS = 10;

const FilterContext = createContext({} as IFilterContext);

const initialValues: IFilterValues = {
  isRetainerActive: false,
  isRetainer: {
    from: undefined,
    to: undefined,
  },
  location: [],
  dealType: [],
  originatedBy: [],
  dealSize: {
    from: undefined,
    to: undefined,
  },
  industries: [],
  pagination: {
    limit: LIMIT_ITEMS,
  },
  dealsCount: 0,
  successFee: {
    from: undefined,
    to: undefined,
  },
};

function FilterContextProvider({ children }: { children: ReactNode }) {
  const [dealsCount, setDealsCount] = useState(0);
  const [marketplaceDealFilter, setMarketplaceDealFilter] = useState([]);
  const [marketplaceDeals, setMarketplaceDeals] = useState([]);
  const [collaborationsMadeToday, setCollaborationsMadeToday] = useState(0);
  const { showErrorToast } = useContext(AlertContext);
  const { setFieldValue, values: filterValues, resetForm } = useForm<IFilterValues>({
    initialValues,
  });

  // filters applied
  const [filtersApplied, setFiltersApplied] = useState([]);

  // set page -- pagination
  const [page, setPage] = useState(LIMIT_ITEMS);

  const [sort, setSort] = useState<ISelectedSort>(DefaultMMSort);

  const [getMMDeals, { loading: isLoading }] = useLazyQuery(GET_MARKETPLACE_DEALS_V2, {
    fetchPolicy: 'no-cache',
    onCompleted(response: any) {
      setMarketplaceDeals(response.marketplaceDeals);
    },
    onError(error: any) {
      const fallback = { title: 'Error in Filters Service', description: error };
      showErrorToast(fallback, error);
    },
  });

  const [getMMFiltersDeals] = useLazyQuery(GET_MM_FILTERS_COUNT, {
    fetchPolicy: 'no-cache',
    onCompleted(response: any) {
      setMarketplaceDealFilter(response.marketplaceDealFiltersCountTotal.filterCounts);
      setDealsCount(response.marketplaceDealFiltersCountTotal.totalCount);
    },
    onError(error: any) {
      const fallback = { title: 'Error in Filters Service', description: error };
      showErrorToast(fallback, error);
    },
  });

  const [getFirmToastLimit] = useLazyQuery(GET_FIRM_TOAST_LIMIT_QUERY, {
    fetchPolicy: 'no-cache',
    onCompleted(response: any) {
      setCollaborationsMadeToday(response.firmToastLimitQuery.activeCollaborations);
    },
    onError(error: any) {
      const fallback = { title: 'Error getting collaboration limit', description: error };
      showErrorToast(fallback, error);
    },
  });

  const handleClearFilters = () => {
    setFieldValue('pagination', {
      limit: LIMIT_ITEMS,
    });
    setPage(LIMIT_ITEMS);
    setFiltersApplied([]);
    setSort(DefaultMMSort);
    getMMDeals({
      variables: {
        filters: [],
        sorts: [DefaultMMSort],
        pagination: {
          limit: LIMIT_ITEMS,
        },
      },
    });
    getMMFiltersDeals({
      variables: {
        filterNames: [
          'TYPE',
          'STATUS',
          'EXPECTED_SIZE',
          'PRIMARY_INDUSTRY',
          'SECONDARY_INDUSTRIES',
          'LOCATION',
          'STATE',
          'IS_RETAINER',
          'RETAINER_AMOUNT',
          'FIRM_ID',
        ],
      },
    });
  };

  // TODO: This method will be transform in each PR because we add new filters -- WIP
  const handleChangeFilter = (filters: any) => {
    setFiltersApplied(filters);
    getMMDeals({
      variables: {
        filters: filters,
        sorts: [sort],
        pagination: {
          limit: page,
        },
      },
    });
    getMMFiltersDeals({
      variables: {
        filters,
        filterNames: [
          'TYPE',
          'STATUS',
          'EXPECTED_SIZE',
          'PRIMARY_INDUSTRY',
          'SECONDARY_INDUSTRIES',
          'LOCATION',
          'STATE',
          'IS_RETAINER',
          'RETAINER_AMOUNT',
          'FIRM_ID',
        ],
      },
    });
  };

  const handleChangeSort = (sortOption: ISelectedSort) => {
    getMMDeals({
      variables: {
        filters: filtersApplied,
        pagination: {
          limit: page,
        },
        sorts: [
          {
            sortName: sortOption.sortName,
            sortType: sortOption.sortType,
          },
        ],
      },
    });
  };

  const handleShowMore = () => {
    setPage(page + LIMIT_ITEMS);
    setFieldValue('pagination', {
      limit: page + LIMIT_ITEMS,
    });
    getMMDeals({
      variables: {
        filters: filtersApplied,
        sorts: sort,
        pagination: {
          limit: page + LIMIT_ITEMS,
        },
      },
    });
  };

  const handleFilteredRefetch = () => {
    getMMDeals({
      variables: {
        filters: filtersApplied,
        sorts: [sort],
        pagination: {
          limit: filterValues.pagination.limit,
        },
      },
    });
  };

  return (
    <FilterContext.Provider
      value={{
        filterState: {
          filterValues: filterValues,
          resetForm: resetForm,
          setFieldValue: setFieldValue,
        },
        handleChangeFilter: handleChangeFilter,
        handleClearFilters: handleClearFilters,
        handleChangeSort: handleChangeSort,
        handleShowMore: handleShowMore,
        refetchMMDeals: handleFilteredRefetch,
        setSort: setSort,
        getFirmToastLimit: getFirmToastLimit,
        getDealsFilterCount: getMMFiltersDeals,
        collaborationsMadeToday: collaborationsMadeToday,
        marketplaceDealFilter: marketplaceDealFilter,
        marketplaceDeals: marketplaceDeals,
        filtersApplied: filtersApplied,
        isLoading: isLoading,
        dealsCount: dealsCount,
        sort: sort,
      }}
    >
      {children}
    </FilterContext.Provider>
  );
}

export { FilterContext, FilterContextProvider };
