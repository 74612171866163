export enum DealFilterEnum {
  TYPE = 'TYPE',
  PRIMARY_INDUSTRY = 'PRIMARY_INDUSTRY',
  SECONDARY_INDUSTRIES = 'SECONDARY_INDUSTRIES',
  LOCATION = 'LOCATION',
  STATE = 'STATE',
  EXPECTED_SIZE = 'EXPECTED_SIZE',
  EXPECTED_COMMISSIONS = 'EXPECTED_COMMISSIONS',
  STATUS = 'STATUS',
  MODE = 'MODE',
  SHOWN_INTEREST = 'SHOWN_INTEREST',
  IS_RETAINER = 'IS_RETAINER',
  PUBLISHED_AT = 'PUBLISHED_AT',
  CLEAR_FILTERS = 'CLEAR_FILTERS',
  PUBLISHED = 'PUBLISHED',
  OWNER_ID = 'OWNER_ID',
  RETAINER_AMOUNT = 'RETAINER_AMOUNT',
  CALCULATED_PERCENT_RATE = 'CALCULATED_PERCENT_RATE',
  MARKETPLACE_DEAL_ID = 'MARKETPLACE_DEAL_ID',
  ORIGINATED_BY = 'FIRM_ID',
}

export enum sortDirection {
  ASC = 'asc',
  DESC = 'desc',
}

export enum FilterOperator {
  BETWEEN = 'between',
  IN = 'in',
  NOT_EQUAL = 'notEqual',
  EQUAL = 'equal',
  GREATER_OR_EQUAL = 'greaterOrEqualThan',
  RANGE_BETWEEN = 'rangeBetween',
}

export type IOption = {
  value: string;
  label: string;
};

export type IOptionPayload = {
  filterName: DealFilterEnum;
  filterType?: string;
  values?: string[];
  value?: string;
};

export interface mmFilterResult {
  filterName: DealFilterEnum;
  filterType?: string;
  values?: mmFilterResultValue[];
  value?: string;
}

export interface mmFilterResultValue {
  count: number;
  filterElement: string;
  filterParent: string;
}

export interface DealType {
  ASSET_SALE: 'Asset Sale';
  CA_RA_COR: 'Capital Raise - Corporate';
  CA_RA_FUN: 'Capital Raise - Fund';
  CA_RA_EB5: 'Capital Raise - EB-5';
  CONS_ENGAGEMENT: 'Consulting Engagement';
  D_P_P: 'Direct Participation Programs';
  M_N_A_B: 'M&A – Buy Side';
  M_N_A_S: 'M&A – Sell Side';
  OTHER: 'Other';
}

export enum EDealStatus {
  INITIAL_ENGAGEMENT = 'INITIAL_ENGAGEMENT',
  ENGAGEMENT_LETTER = 'ENGAGEMENT_LETTER',
  PRE_MARKETING = 'PRE_MARKETING',
  DUE_DILIGENCE_CLOSING = 'DUE_DILIGENCE_CLOSING',
  ON_HOLD = 'ON_HOLD',
  CLOSED = 'CLOSED',
  EXPIRED = 'EXPIRED',
}

export enum MMTypeCard {
  MM_ACTIVE_DEAL = 'MM_ACTIVE_DEAL',
  MM_MY_DEALS = 'MM_MY_DEALS',
  MM_PRIVATE_DEALS = 'MM_PRIVATE_DEALS',
  MM_COLLABORATE_DEALS = 'MM_COLLABORATE_DEALS',
}

type grossFeedEarnedType = {
  min: string;
  max: string;
};

type CollaborationType = {
  offeringId: string;
  offeringType: MarketplaceType;
  collaborationType: string;
  requestedAt: string;
  status: string;
};

export enum MarketplaceDealType {
  Private = 'Private',
  Collaborative = 'Collaborative',
  NonCollaborative = 'NonCollaborative',
}

export interface IMarketplaceDeal {
  id: string;
  name: string;
  type: DealType;
  marketplaceDealId: string;
  status: EDealStatus;
  primaryIndustry: string;
  secondaryIndustries: string[];
  country: string;
  state: string;
  expectedSize: number;
  tailPeriodDuration: number;
  retainerAmount: number;
  grossFeedEarned: grossFeedEarnedType;
  loiSignedDate: string;
  description: string;
  ownerId: string;
  engagementLetterSignedDate: string;
  published: boolean;
  publishedAt: string;
  marketplaceDealType: MarketplaceDealType;
  collaborateInDeal: CollaborationType;
}

export interface IMarketplaceFund {
  id: string;
  name: string;
  ownerId: string;
  expectedSize: number;
  marketplaceDealId: string;
  primaryIndustry: string;
  secondaryIndustries: string[];
  description: string;
  term: number;
  country: string;
  state: string;
  expectedIrr: {
    min: number;
    max: number;
  };
  expectedReturn: {
    min: number;
    max: number;
  };
  marketplaceDealType: MarketplaceDealType;
  collaborateInFund: CollaborationType;
  publishedAt: string;
  managementFee: number;
}

export type MarketplaceBrowserIProps = {
  onDeleteNonComplianceDeal: (dealId: string) => void;
};

export enum MarketplaceType {
  FUND = 'FUND',
  DEAL = 'DEAL',
}

export enum CollaborateTypeEnum {
  EXECUTION = 'EXECUTION',
  RESEARCH = 'RESEARCH',
  OTHER = 'OTHER',
}

export enum StatusCollaborationEnum {
  PENDING = 'PENDING',
  CLOSED = 'CLOSED',
}

export interface ICollaboration {
  offeringId: string;
  offeringType: MarketplaceType;
  requesterId: string;
  offeringFirmId: string;
  collaborationType: CollaborateTypeEnum;
  status: StatusCollaborationEnum;
  requestedAt: string;
  offeringDetail: {
    marketplaceDealId: string;
    expectedSize: number;
    primaryIndustry: string;
    name: string;
    type: DealType;
    term: number;
  };
  offeringOwnerReferenceName: string;
  newMessagesAmount: any;
}

export interface IChatMessage {
  collaborationId: string;
  dateTime: string;
  id: string;
  message: string;
  userId: string;
  userName: string;
  firmId: string;
  files: [];
}

export interface IDateMessage {
  type: string;
  date: string;
  id: string;
}

export enum ConversationUserSideEnum {
  OFFERING_FIRM_MEMBER = 'OFFERING_FIRM_MEMBER',
  REQUESTER_FIRM_MEMBER = 'REQUESTER_FIRM_MEMBER',
}
