import { DealFilterEnum, FilterOperator } from 'app/marketplace/types';
import { IFilterValues } from '../../../context/TypedFilterContext';

const mapAdvancedFilters = (advancedFilters: IFilterValues) => {
  const filter: any[] = [];

  const mapRetainerFilter = () => {
    const { isRetainer, isRetainerActive } = advancedFilters;
    const { from, to } = isRetainer;
    if (isRetainerActive) {
      if ((from !== undefined && to) || (from === undefined && to === undefined) || to) {
        filter.push({
          filterName: DealFilterEnum.RETAINER_AMOUNT,
          filterType: FilterOperator.BETWEEN,
          from: from !== undefined ? String(from) : '0',
          to: to !== undefined ? String(to) : '999999999',
        });
      } else {
        filter.push({
          filterName: DealFilterEnum.RETAINER_AMOUNT,
          filterType: FilterOperator.GREATER_OR_EQUAL,
          value: String(from),
        });
      }
    }
  };

  const mapDealSizeFilter = () => {
    const { dealSize } = advancedFilters;
    const { from, to } = dealSize;
    if ((from !== undefined && to) || to) {
      filter.push({
        filterName: DealFilterEnum.EXPECTED_SIZE,
        filterType: FilterOperator.BETWEEN,
        from: from !== undefined ? String(from) : '0',
        to: to !== undefined ? String(to) : '999999999',
      });
    } else if (from !== undefined) {
      filter.push({
        filterName: DealFilterEnum.EXPECTED_SIZE,
        filterType: FilterOperator.GREATER_OR_EQUAL,
        value: String(from),
      });
    }
  };

  const mapDealSuccesFeeFilter = () => {
    const { successFee } = advancedFilters;
    const { from, to } = successFee;
    if ((from !== undefined && to !== undefined) || to) {
      filter.push({
        filterName: DealFilterEnum.CALCULATED_PERCENT_RATE,
        filterType: FilterOperator.RANGE_BETWEEN,
        from: from !== undefined ? String(from) : '0',
        to: to !== undefined ? String(to) : '100',
      });
    } else if (from !== undefined) {
      filter.push({
        filterName: DealFilterEnum.CALCULATED_PERCENT_RATE,
        filterType: FilterOperator.RANGE_BETWEEN,
        from: String(from),
        to: '100',
      });
    }
  };

  const mapLocationFilter = () => {
    const { location } = advancedFilters;
    if (location) {
      const primaryLocation = location.map((x: any) => x.primary);
      const secondaryLocation = new Set();
      location.forEach((primary: any) => {
        primary.secondary.forEach((secondary: any) => {
          secondaryLocation.add(secondary);
        });
      });
      if (primaryLocation.length > 0) {
        filter.push(
          // {  TODO: THIS WILL BE USED WHEN THE LOCATION FILTER WORKS CORRECTLY
          //   filterName: DealFilterEnum.LOCATION,
          //   filterType: FilterOperator.IN,
          //   values: primaryLocation,
          // },
          {
            filterName: DealFilterEnum.STATE,
            filterType: FilterOperator.IN,
            values: Array.from(secondaryLocation),
          }
        );
      }
    }
  };

  const mapDealSimpleFilter = (values: any, filterName: DealFilterEnum) => {
    if (values.length > 0) {
      filter.push({
        filterName,
        filterType: FilterOperator.IN,
        values,
      });
    }
  };

  const mapMulticheckFilter = (
    value: any,
    filterPrimaryName: DealFilterEnum,
    filterSecondaryName: DealFilterEnum
  ) => {
    if (value) {
      const primaryValues = value.map((x: any) => x.primary);
      const secondaryValues = new Set();
      value.forEach((primary: any) => {
        primary.secondary.forEach((secondary: any) => {
          secondaryValues.add(secondary);
        });
      });
      if (primaryValues.length > 0) {
        filter.push(
          {
            filterName: filterPrimaryName,
            filterType: FilterOperator.IN,
            values: primaryValues,
          },
          {
            filterName: filterSecondaryName,
            filterType: FilterOperator.IN,
            values: Array.from(secondaryValues),
          }
        );
      }
    }
  };

  mapDealSizeFilter();
  mapDealSuccesFeeFilter();
  mapDealSimpleFilter(advancedFilters.dealType, DealFilterEnum.TYPE);
  mapMulticheckFilter(
    advancedFilters.industries,
    DealFilterEnum.PRIMARY_INDUSTRY,
    DealFilterEnum.SECONDARY_INDUSTRIES
  );
  mapLocationFilter();
  mapRetainerFilter();
  return filter;
};

export default mapAdvancedFilters;
