// Types
import { DealFilterEnum, FilterOperator } from 'app/marketplace/types';
import { BANKER, FINALIS_CONNECT } from 'app/utils/constants/app/appConstants';
import { GA_CATEGORY } from 'app/utils/types/types';

export const mapDealSizeFilter = (
  dealSize: {
    from: number | undefined;
    to: number | undefined;
  },
  trackSingleEvent: (category: string, action: string, label: string) => void
) => {
  const { from, to } = dealSize;
  if (from === undefined && to === undefined) {
    return [];
  }
  if ((from && to) || to) {
    trackSingleEvent(
      GA_CATEGORY.FILTERS,
      DealFilterEnum.EXPECTED_SIZE,
      to ? String(to) : '999999999'
    );
    return [
      {
        filterName: DealFilterEnum.EXPECTED_SIZE,
        filterType: FilterOperator.BETWEEN,
        from: from ? String(from) : '0',
        to: to ? String(to) : '999999999',
      },
    ];
  }
  trackSingleEvent(GA_CATEGORY.FILTERS, DealFilterEnum.EXPECTED_SIZE, String(from));
  return [
    {
      filterName: DealFilterEnum.EXPECTED_SIZE,
      filterType: FilterOperator.GREATER_OR_EQUAL,
      value: String(from),
    },
  ];
};

export const mapIndustryFilter = (
  filter: { primary: string; secondary: string[] }[],
  trackMultipleEvents: (category: string, action: string, labels: string[]) => void
) => {
  if (filter) {
    const primaryIndustry = filter.map((x: any) => x.primary);
    const secondaryIndustry = new Set();
    filter.forEach((primary: any) => {
      primary.secondary.forEach((secondary: any) => {
        secondaryIndustry.add(secondary);
      });
    });
    if (primaryIndustry.length > 0) {
      trackMultipleEvents(GA_CATEGORY.FILTERS, DealFilterEnum.PRIMARY_INDUSTRY, primaryIndustry);
      trackMultipleEvents(GA_CATEGORY.FILTERS, DealFilterEnum.SECONDARY_INDUSTRIES, Array.from(
        secondaryIndustry
      ) as string[]);
      return [
        {
          filterName: DealFilterEnum.PRIMARY_INDUSTRY,
          filterType: FilterOperator.IN,
          values: primaryIndustry,
        },
        {
          filterName: DealFilterEnum.SECONDARY_INDUSTRIES,
          filterType: FilterOperator.IN,
          values: Array.from(secondaryIndustry),
        },
      ];
    }
  }
  return [];
};

export const mapDealSimpleFilter = (
  filter: string[],
  filterName: DealFilterEnum,
  trackMultipleEvents: (category: string, action: string, labels: string[]) => void
) => {
  if (filter.length > 0) {
    trackMultipleEvents(GA_CATEGORY.FILTERS, filterName, filter);
    return [
      {
        filterName,
        filterType: FilterOperator.IN,
        values: filter,
      },
    ];
  }
  return [];
};

export const mapOriginatedByFilter = (
  filter: string[],
  filterName: DealFilterEnum,
  trackMultipleEvents: (category: string, action: string, labels: string[]) => void
) => {
  if (filter.length > 0) {
    const array: any[] = [];
    if (filter.includes(FINALIS_CONNECT) && filter.includes(BANKER)) {
      return array;
    }
    filter.forEach(item => {
      if (item === FINALIS_CONNECT) {
        array.push({
          filterName,
          filterType: FilterOperator.EQUAL,
          value: process.env.REACT_APP_FIRM_ID_PRODUCTION_WHITELIST,
        });
      }
      if (item === BANKER) {
        array.push({
          filterName,
          filterType: FilterOperator.NOT_EQUAL,
          value: process.env.REACT_APP_FIRM_ID_PRODUCTION_WHITELIST,
        });
      }
    });
    trackMultipleEvents(GA_CATEGORY.FILTERS, filterName, filter);
    return array;
  }
  return [];
};
